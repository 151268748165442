<template>
    <CRow class='col-12'>
        <CToaster position='top-right' :autohide='1500'>
            <template v-for='(toast, index) in toasts'>
                <CToast
                    :key="'toast' + toast.text + index"
                    :show='true'
                    :header="toast.header === '' ? null : toast.header"
                >
                    {{ toast.text }}
                </CToast>
            </template>
        </CToaster>
        <CCard class='col-12 col-xl-4 mr-5'>
            <CCardHeader>
                <strong> İndirim Kodu Oluştur</strong>
            </CCardHeader>
            <CCardBody>
                <template #label>
                    Social Security Number
                </template>
                <CRow>
                    <CCol col='12'>
                        <CInput
                            label='İndirim Kodu'
                            placeholder='İndirim kodunu giriniz'
                            v-model='discountCode'
                        />
                    </CCol>
                </CRow>
                <CRow>
                    <CCol col='12'>
                        <CInput
                            label='İndirim Yüzdesi'
                            placeholder='İndirim oranını giriniz'
                            v-model='discountPercentage'
                            description='1 ile 99 arasında bir değer giriniz.'
                        />
                    </CCol>
                </CRow>
                <CRow col='12' md='6' class='mt-3'>
                    <CCol>
                        <CButton @click='createDiscount' block color='success'>Yeni Kod Oluştur</CButton>
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>

        <CCard class='col-xl-7 col-md-12'>
            <CCardHeader>
                <strong> Oluşturulan İndirim Kodları</strong>
            </CCardHeader>
            <CCardBody>
                <CInput label='Arama: ' placeholder='Aramak istediğiniz indirim kodunu yazınız...' :horizontal="{ label: 'w-auto', input: 'w-100'}" v-model='filterValue' />
                <CDataTable
                    class='mb-0 table-outline'
                    hover
                    :items='tableItems'
                    :fields='tableFields'
                    :items-per-page='itemsPerPage'
                    head-color='light'
                    :loading='isDataLoading'
                >
                    <td slot='id' slot-scope='{item}'>
                        <div>{{ item.id }}</div>
                        <div class='small text-muted'>

                        </div>
                    </td>
                    <td slot='code' slot-scope='{item}'>
                        <div class='clearfix'>
                        </div>
                        <div>{{ item.code }}</div>
                    </td>
                    <td slot='discountPercentage' slot-scope='{item}'>
                        <div class='clearfix'>
                        </div>
                        <div>{{ item.discountPercentage }}</div>
                    </td>
                    <td slot='isActive' slot-scope='{item}'>
                        <div class='clearfix'>
                        </div>
                        <CSwitch
                            class='mx-1'
                            color='success'
                            v-bind='labelIcon'
                            :checked='item.isActive'
                            @update:checked='changeActiveState(item.id, $event)'
                        />
                    </td>
                    <td slot='delete' slot-scope='{item}'>
                        <CButton @click='deleteDiscountAlert(item.id)' color='danger'>
                            <CIcon name='cil-x' />
                        </CButton>
                    </td>
                </CDataTable>
                <CPagination
                    v-if='totalPages !== 0'
                    :pages='totalPages'
                    :active-page='currentPage'
                    :activePage.sync='currentPage'
                    align='center'
                    class='mt-3'
                />
            </CCardBody>
        </CCard>
    </CRow>
</template>

<script>
import { PromotionAdministration } from '@/services/api-service'

export default {
    name: 'DiscountCodes',
    components: {},
    mounted() {
        this.loadDiscounts()
    },
    watch: {
        filterValue() {
            this.loadDiscounts()
        },
        currentPage() {
            this.loadDiscounts()
        },
        itemsPerPage() {
            this.loadDiscounts()
        },
    },
    data() {
        return {
            isCodeActive: false,
            labelIcon: {
                labelOn: '\u2713',
                labelOff: '\u2715',
            },
            isDataLoading: false,
            filterValue: '',
            discountCode: '',
            discountPercentage: 0,
            currentPage: 1,
            itemsPerPage: 50,
            totalPages: 1,
            toasts: [],
            tableItems: [],
            tableFields: [
                { key: 'id', label: 'ID' },
                { key: 'code', label: 'İndirim Kodu' },
                { key: 'discountPercentage', label: 'İndirim Yüzdesi' },
                { key: 'isActive', label: 'Durumu' },
                { key: 'delete', label: 'Sil' },
            ],
        }
    },
    methods: {
        color(value) {
            let $color
            if (value <= 25) {
                $color = 'info'
            } else if (value > 25 && value <= 50) {
                $color = 'success'
            } else if (value > 50 && value <= 75) {
                $color = 'warning'
            } else if (value > 75 && value <= 100) {
                $color = 'danger'
            }
            return $color
        },
        deleteDiscountAlert(id) {
            this.$swal({
                title: 'Emin misiniz?',
                text: 'Yaptığınız İşlem Geri Alınamayacaktır!',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet, Sil',
                cancelButtonText: 'Vazgeç',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.tableItems = this.tableItems.filter(r => r.id !== id)
                    await PromotionAdministration.deleteDiscount(id)
                    await this.$swal.fire(
                        'Silindi!',
                        'İşlem Başarıyla Gerçekleştirildi!',
                        'success',
                    )
                }
            })
        },

        async changeActiveState(id, isActive) {
            await PromotionAdministration.changeDiscountActiveState(id, isActive)
        },


        showToast: function(text = 'Başarıyla oluşturuldu.', header = 'Bilgi') {
            this.toasts.push({ text, header })
        },
        async loadDiscounts() {
            this.isDataLoading = true
            try {
                const result = await PromotionAdministration.listDiscounts(this.currentPage, this.itemsPerPage, this.filterValue)
                this.tableItems = result.data.discountsList
                this.totalPages = result.data.pagination.totalPages
            } catch (e) {
                this.toasts.push('Veri Güncellenemedi', 'Hata')
            }
            this.isDataLoading = false
        },
        async createDiscount() {
            this.isDataLoading = true
            try {
                const result = await PromotionAdministration.createDiscount(this.discountCode, this.discountPercentage)
                const newDiscountCode = {
                    code: this.discountCode,
                    discountPercentage: this.discountPercentage,
                    id: result.data.createdDiscountCodeId,
                    isActive: true,
                }
                this.tableItems = [newDiscountCode, ...this.tableItems]
                this.discountCode = ''
                this.discountPercentage = 0
            } catch (e) {
                this.toasts.push('Veri Güncellenemedi', 'Hata')
            }
            this.isDataLoading = false
        },
    },
}
</script>
